const Notification = () => {
    return(
        <>
            <div className="card notification">
                <div className="card-body">
                    <ul>
                        <li>Lorem Ipsum is simply dummy text of...</li>
                        <li>Lorem Ipsum is simply dummy text of...</li>
                        <li>Lorem Ipsum is simply dummy text of...</li>
                        <li>Lorem Ipsum is simply dummy text of...</li>
                        <li>Lorem Ipsum is simply dummy text of...</li>
                    </ul>
                </div>
                <div className="card-footer">
                    <a href="#">Nofitication List</a>
                </div>
            </div>
        </>
    );
}

export default Notification;