import { Link, Navigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSignOut } from '@fortawesome/free-solid-svg-icons';

import { useDispatch, useSelector } from "react-redux";
import NavContent from './navcontent/NavContent';
import { useState } from 'react';

import runDecrypt from '../../container/hash/Decrypt';

const Navbar = () => {
    let profile = JSON.parse(runDecrypt.runDecodeEncrypt(localStorage.getItem('useruuids')));

    // const navNotification = useSelector(state => state.navNotification);
    const navAccount = useSelector(state => state.navAccount);
    // const navTranslation = useSelector(state => state.navTranslation);

    const dispatch = useDispatch();

    const [isLogout, setIsLogout] = useState(false);

    // const notificationHandle = () => {
    //     if(navNotification === false){
    //         dispatch({
    //             type : 'BOX_NOTIFICATION',
    //             newNavNotification : true
    //         });
    //         document.getElementById("btnAccount").disabled = true;
    //         document.getElementById("btnTranslation").disabled = true;
    //     }else{
    //         dispatch({
    //             type : 'BOX_NOTIFICATION',
    //             newNavNotification : false
    //         });
    //         document.getElementById("btnAccount").disabled = false;
    //         document.getElementById("btnTranslation").disabled = false;
    //     }
    // }

    const accountHandle = () => {
        if(navAccount === false){
            dispatch({
                type : 'BOX_ACCOUNT',
                newNavAccount : true
            });
            document.getElementById("btnNotification").disabled = true;
            document.getElementById("btnTranslation").disabled = true;
        }else{
            dispatch({
                type : 'BOX_ACCOUNT',
                newNavAccount : false
            });
            document.getElementById("btnNotification").disabled = false;
            document.getElementById("btnTranslation").disabled = false;
        }
    }

    // const translationHandle = () => {
    //     if(navTranslation === false){
    //         dispatch({
    //             type : 'BOX_TRANSLATION',
    //             newNavTranslation : true
    //         });
    //         document.getElementById("btnNotification").disabled = true;
    //         document.getElementById("btnAccount").disabled = true;
    //     }else{
    //         dispatch({
    //             type : 'BOX_TRANSLATION',
    //             newNavTranslation : false
    //         });
    //         document.getElementById("btnNotification").disabled = false;
    //         document.getElementById("btnAccount").disabled = false;
    //     }
    // }

    const logout = () => {
        setIsLogout(true);
    }

    if(isLogout){
        return <Navigate to={'/logout'}/>
    }

    return(
        <>
            <div className="navbar">
                <div className="navbar-brand">
                    <Link to={`/`}><img src={process.env.PUBLIC_URL+'/images/components/237567@2x.png'} alt="brand"></img></Link>
                </div> 
                <div className="navbar-content">
                    { localStorage.getItem('useruuids') === null ? <>
                    <Link to={'/login'} className="btn btn-primary btw-100 mr-20">Login</Link>
                    <Link to={'/new-register'} className="btn btn-outline-primary btw-100 mr-20 register">Register</Link></> : <>
                    {/* <button className="btn btn-primary btw-200 mr-50 m-auto">Transction History</button> */}
                    {/* <button type="button" className="position-relative mr-50 btn-xdw m-auto" id="btnNotification" onClick={notificationHandle}>
                        <FontAwesomeIcon icon={faBell} size="lg" className="pt-2"/>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            1<span className="visually-hidden">unread messages</span>
                        </span>
                    </button> */}
                    <button onClick={logout} className="btn-xdw logout">
                        <FontAwesomeIcon icon={faSignOut} className="pl-10 signout pointer" />
                    </button>
                    <button className="btn btn-light btw-200 mr-50 m-auto" id="btnAccount" onClick={accountHandle}>
                        { profile.tableuserdisplayname }
                        <FontAwesomeIcon icon={faAngleDown} className="pl-10"/>
                    </button>
                    </>}
                    {/* <button className="btn-xdw m-auto" id="btnTranslation">
                        <FontAwesomeIcon icon={faGlobe} size="lg" className="pt-2 mr-50 pointer" onClick={translationHandle}/>
                    </button> */}
                </div>
                <div className="nav-list-content">
                    <NavContent/>
                </div>
            </div>
        </>
    );
};

export default Navbar