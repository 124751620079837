import jsonNisar from '../library/XDWPRIVATESALE.json';

const Language = (params) => {
    let getDefaultLangApps = localStorage.getItem('LangApps') ? localStorage.getItem('LangApps') : 'EN';
    let getNisarJson = jsonNisar[getDefaultLangApps][params] ? jsonNisar[getDefaultLangApps][params] : params;

    return getNisarJson;
}

const runService = {
    getServiceLanguage(params){
        return Language(params);
    }
}

export default runService;
