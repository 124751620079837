import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Template = () => {
    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <div className="alert alert-primary" role="alert">
                        A simple primary alert—check it out!
                    </div>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                    asdasd<br/>
                </div>
            </div>
        </>
    );
}

export default Template;