import { Link } from 'react-router-dom';

const Account = () => {
    let params = window.location.pathname;
    
    return(
        <>
            <div className="card account">
                <div className="card-body">
                    <div className="minus-ml26">
                        <ul className="account-list">
                            {/* <li><Link to={'/profile'} className={params === '/profile' ? 'active' : ''}>Profile</Link></li>
                            <li><Link to={'/main-wallet'} className={params === '/main-wallet' ? 'active' : ''}> Main Wallet</Link></li>
                            <ul className="account-list">
                                <li><a href="#">TU</a></li>
                                <li><a href="#">WD</a></li>
                                <li><a href="#">Transfer</a></li>
                            </ul>
                            <li>Saved Wallet</li>
                            <ul className="account-list">
                                <li><a href="#">Create Saved Wallet</a></li>
                                <li><a href="#">Saved Wallet List</a></li>
                            </ul>
                            <li>Lingked Account</li>
                            <ul className="account-list">
                                <li><a href="#">Create Lingked Account</a></li>
                                <li><a href="#">Lingked Account List</a></li>
                            </ul> */}
                            <li><a href="/logout">Log Out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account;