import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    return(
        <>
            <div className="footer">
                &copy; 2022 XDW<br/>
                <a href="mailto:info@xdw.systems"><FontAwesomeIcon icon={faEnvelope}/>  info@xdw.systems</a>
            </div>
        </>
    )
}

export default Footer;