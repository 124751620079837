import { Link } from "react-router-dom";

const Sidebar = () => {
    return(
        <>
            <div className="sidebar">
                <div className="brand">
                    <Link to={'/home'}>
                        <img src={process.env.PUBLIC_URL+'/images/components/237567@2x.png'} alt="brand"></img>
                    </Link>
                </div>
                <div className="main-menu">
                </div>
            </div>
        </>
    );
}

export default Sidebar;