import { useEffect, useState } from "react";
import { Navigate, Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";

import Navbar from "../layout/Navbar";
import runDecrypt from "../../container/hash/Decrypt";

import Footer from "../layout/Footer";

import runService from "../library/Service";

const Register = () => {
    const [referralCode, setReferralCode] = useState();
    const [tableUser, setTableUser] = useState({});

    const [tableUsername, setTableUsername] = useState('');
    const [tableUserFullName, setTableUserFullName] = useState('');
    const [tableUserDisplayName, setTableUserDisplayName] = useState('');
    const [tableUserEmail, setTableUserEmail] = useState('');

    const [isCodeError, setCodeError] = useState('default');
    const [errorMessage, setErrorMessage] = useState(null);

    const [isRedirect, setIsRedirect] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if(localStorage.getItem('tokenTrans') === null){
            window.location.href = '/';
        }
    }, [])

    useEffect(() => {
        setReferralCode(runDecrypt.runDecodeEncrypt(localStorage.getItem('referralCode')));

        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += localStorage.getItem('tokenTrans');

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/V01/detailid',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                tokentrans : localStorage.getItem('tokenTrans')
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setTableUser(res.data.tableuser[0]);
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        setCodeError('default');
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setBtnLoading(true);
        
        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += localStorage.getItem('tokenTrans');
        StringSignature += tableUsername;
        StringSignature += tableUserFullName;
        StringSignature += tableUserDisplayName;
        StringSignature += tableUserEmail;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/V01/store',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                tokentrans : localStorage.getItem('tokenTrans'),
                tableusername : tableUsername,
                tableuserfullname : tableUserFullName,
                tableuserdisplayname : tableUserDisplayName,
                tableuseremail : tableUserEmail,
                tableuserlanguage : 'EN'

            }
        }).then(res => {
            if(res.data.status.status === 1){
                dispatch({
                    type : 'PAGE_SUCCESS',
                    newPageSuccess : 'registrationSuccess'
                });

                localStorage.removeItem('tokenTrans');
                setIsRedirect(true);
            }else{
                console.log(res.data.status);
                setCodeError(res.data.status.code.substring(0, 9));
                setErrorMessage(res.data.status.code);
            }
            setBtnLoading(false)
        }).catch(error => {
            console.log(error);
        })
    }

    if(isCodeError === '888999999955'){
        return <Navigate to={`/new-register`}/>
    }

    if(isRedirect){
        return <Navigate to={`/success`}/>
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                {btnLoading ? <>
                <div className="backdrop"></div> 
                <div className="loading">
                    <img src={process.env.PUBLIC_URL + '/images/components/logo-load.gif'} alt="load-logo"/>
                </div></> : '' }
                <div className="container mt-100">
                    <div className="formRegister">
                        <div className="bg-regis">
                            <div className="img-regis">
                                <img src={process.env.PUBLIC_URL+'/images/components/register.png'} alt="register.png" className="m-auto d-block"></img>
                            </div>
                            <div className="des-regis">
                                <h3>Let's get you set up</h3>
                                <p>Create your own dream token without needing any skill</p>
                            </div>
                        </div>
                        <div className="formRegis">
                            <div className="card shadow">
                                <div className="card-body mt-3 pl-34 pr-34">
                                    <h3>Register</h3>
                                    <form method="POST" onSubmit={onSubmit}>
                                        <div className="mb-3 mt-4">
                                            <label htmlFor="referralcode" className="form-label">Referral Code</label>
                                            <input type="text" name="referralcode" className="form-control" defaultValue={referralCode} disabled/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tableuseremail" className="form-label">Email</label>
                                            <input type="text" name="tableuseremail" placeholder="user@xdw.systems" className={ isCodeError === '888065030' ? 'form-control border border-danger' : 'form-control'} defaultValue={tableUser.tempuseremail} onChange={(e) => setTableUserEmail(e.target.value)}/>
                                            {isCodeError === '888065030' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tableuserfullname" className="form-label">Full Name</label>
                                            <input type="text" name="tableuserfullname" placeholder="User XDW" className={ isCodeError === '888065020' ? 'form-control border border-danger' : 'form-control'} defaultValue={tableUser.tempuserfullname} onChange={(e) => setTableUserFullName(e.target.value)}/>
                                            {isCodeError === '888065020' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null }
                                            <span className="description">(Length must be more than 5 character and must not contain numeric)</span>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tableuserdisplayname" className="form-label">Display Name</label>
                                            <input type="text" name="tableuserdisplayname" placeholder="XDW User" className={ isCodeError === '888065025' ? 'form-control border border-danger' : 'form-control'} defaultValue={tableUser.tempuserdisplayname} onChange={(e) => setTableUserDisplayName(e.target.value)}/>
                                            {isCodeError === '888065025' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null }
                                            <span className="description">(Length must be more than 5 character and must not contain numeric)</span>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tableusername" className="form-label">Username</label>
                                            <input type="text" name="tableusername" placeholder="xdwuser1" className={ isCodeError === '888065015' ? 'form-control border border-danger' : 'form-control'} defaultValue={tableUser.tempusername} onChange={(e) => setTableUsername(e.target.value)}/>
                                            {isCodeError === '888065015' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null }
                                            <span className="description">(Length must be between 5-100 character and must not start with numeric)</span>
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <button type="submit" disabled={btnLoading ? 'disabled' : ''} className="btn btn-primary btw-150 m-auto d-block">Register</button>
                                            <span className="text-center d-block pt-3">Already have an account? <Link to={`/login`} className="text-decoration-none">Sign in</Link></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Register;