import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './components/Styles.css';

import Router from './routers/Router';

document.title = 'XDW Systems';

const globalState = {
	pageSuccess: '',
	modalPassword : false,
	ResetFormProfile : '',
	notif : '',
	navNotification : false,
	navAccount : false,
	navTranslation : false
}

const rootReducer = (state = globalState, action) => {
	switch(action.type){
		case 'PAGE_SUCCESS':
			return{
				...state,
				pageSuccess : action.newPageSuccess
			}
		case 'MODAL_PASSWORD':
			return{
				...state,
				modalPassword : action.newModalPassword
			}
		case 'RESET_FORM_PROFILE':
			return{
				...state,
				ResetFormProfile : action.newResetFormProfile
			}
		case 'NOTIF':
			return{
				...state,
				notif : action.newNotif
			}
		case 'BOX_NOTIFICATION':
			return{
				...state,
				navNotification : action.newNavNotification
			}
		case 'BOX_ACCOUNT':
			return{
				...state,
				navAccount : action.newNavAccount
			}
		case 'BOX_TRANSLATION':
			return{
				...state,
				navTranslation : action.newNavTranslation
			}
		default : 
			return state;
	}
}

const store = createStore(rootReducer);

const App = () => {
	return(
		<>
			<Provider store={store}>
				<Router/>
			</Provider>
		</>
	)
}

export default App;