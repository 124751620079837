import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";

import cryptoJS from "crypto-js";
import axios from "axios";

import runEncrypt from '../../container/hash/Encrypt';

const NewRegister = () => {
    const { refby } = useParams();
    const [isRedirect, setIsRedirect] = useState(false);

    useEffect(() => {
        let referralCode;
        let EncryptReferralCode;

        localStorage.removeItem('referralCode');
        localStorage.removeItem('useruuids');

        if(refby === undefined){
            referralCode = '';
            EncryptReferralCode = null;
        }else{
            referralCode = refby;
            EncryptReferralCode = runEncrypt.runEncodeEncrypt(refby);
        }

        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += referralCode;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/V01/insertnew',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                tableuserreferredby : referralCode
            }
        }).then(res => {
            if(res.data.status.status === 1){
                localStorage.setItem('tokenTrans', res.data.status.tokentrans);
                if(EncryptReferralCode !== null){
                    localStorage.setItem('referralCode', EncryptReferralCode);
                }
                setIsRedirect(true);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [refby]);

    if(isRedirect){
        return <Navigate to={`/register`}/>
    }

    return(
        <>
            Please wait, it is being directed...
        </>
    );
}

export default NewRegister;