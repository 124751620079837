import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Navbar from "../layout/Navbar";
import Footer from '../layout/Footer';

const Success = () => {
    const pageSuccess = useSelector(state => state.pageSuccess);
    return(
        <>
            <div className="application">
                <Navbar/>
                { pageSuccess === 'registrationSuccess' ? 
                <div className="container mt-100">
                    <div className="card bg-success w-404 d-block m-auto">
                        <div className="card-body">
                            <div className="img-success mt-5">
                                <img src={process.env.PUBLIC_URL+'/images/components/register-success.png'} alt="register-success.png"/>
                            </div>
                            <div className="des-success mb-5">
                                <h3>Success Registration</h3>
                                <p>Our system just sent an email to create initial password</p>
                            </div>
                        </div>
                    </div>
                </div> : null}

                { pageSuccess === 'emailSuccess' ? 
                <div className="container mt-100">
                    <div className="card bg-success w-404 d-block m-auto">
                        <div className="card-body">
                            <div className="img-success mt-5">
                                <img src={process.env.PUBLIC_URL+'/images/components/warning.png'} alt="warning.png"/>
                            </div>
                            <div className="des-success mt-3">
                                <h3>Warning</h3>
                                <p>send username to your email</p>
                            </div>
                        </div>
                    </div>
                </div> : null }

                { pageSuccess === 'emailSuccessForgot' ? 
                <div className="container mt-100">
                    <div className="card bg-success w-404 d-block m-auto">
                        <div className="card-body">
                            <div className="img-success mt-5">
                                <img src={process.env.PUBLIC_URL+'/images/components/warning.png'} alt="warning.png"/>
                            </div>
                            <div className="des-success mt-3">
                                <h3>Warning</h3>
                                <p>If the email is registered we will send username to yout link</p>
                            </div>
                        </div>
                    </div>
                </div> : null }

                { pageSuccess === 'passwordSuccess' ? 
                <div className="container mt-100">
                    <div className="card bg-success w-404 d-block m-auto">
                        <div className="card-body">
                            <div className="img-success mt-5">
                                <img src={process.env.PUBLIC_URL+'/images/components/new-password-success.png'} alt="new-password-success.png"/>
                            </div>
                            <div className="des-success">
                                <h3>Success</h3>
                                <p>Create new password</p>
                            </div>
                            <div className="d-block text-center mb-5">
                                <Link to={`/login`} className="btn btn-primary">Go to login</Link>
                            </div>
                        </div>
                    </div>
                </div> : null }
            </div>
            <Footer/>
        </>
    );
}

export default Success;