import { useCallback, useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import axios from "axios";
import cryptoJS from "crypto-js";
import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import ModalPassword from "../../modal/ModalPassword";

const Profile = () => {
    const [profile, setProfile] = useState({});
    const [copyied, setCopyied] = useState(false);
    const copyClipboardValue = useRef('');
    const [isRedirect, setRedirect] = useState(false);

    const [tableuserfullname, setTableuserfullname] = useState('');
    const [tableuserdisplayname, setTableuserdisplayname] = useState('');
    const [tableusername, setTableusername] = useState('');

    const modalPassword = useSelector(state => state.modalPassword);
    const ResetFormProfile = useSelector(state => state.ResetFormProfile);

    const dispatch = useDispatch();
    const alert = useAlert();

    const profileLoad = useCallback(() => {
        let StringSignature = '';
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/V01/profile',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                username : useruuid.tableusername,
                tokenlogin : useruuid.tokenlogin
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setProfile(res.data.result);
                setTableuserfullname(res.data.result.tableuserfullname);
                setTableuserdisplayname(res.data.result.tableuserdisplayname);
                setTableusername(res.data.result.tableusername);
            }
        })
    }, []);

    useEffect(() => {
        profileLoad();
    }, [profileLoad]);

    const saveHandleConfirmation = (userPassword) => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        if(ResetFormProfile === 'full_name'){
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += userPassword;
            StringSignature += tableuserfullname;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/V01/changeuserfullname',
                headers : {
                    signature : signature
                },
                data : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    username : useruuid.tableusername,
                    tokenlogin : useruuid.tokenlogin,
                    userpassword : userPassword,
                    tableuserfullname : tableuserfullname
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    localStorage.removeItem('useruuid');
                    dispatch({
                        type : 'NOTIF',
                        newNotif : 'Please Re-login!'
                    });
                    setRedirect(true);
                }
                else{
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });

                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    alert.show(res.data.status.code);
                    profileLoad();                    
                }
            })
        }

        if(ResetFormProfile === 'display_name'){
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += userPassword;
            StringSignature += tableuserdisplayname;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/V01/changeuserdisplayname',
                headers : {
                    signature : signature
                },
                data : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    username : useruuid.tableusername,
                    tokenlogin : useruuid.tokenlogin,
                    userpassword : userPassword,
                    tableuserdisplayname : tableuserdisplayname
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    localStorage.removeItem('useruuid');
                    dispatch({
                        type : 'NOTIF',
                        newNotif : 'Please Re-login!'
                    });
                    setRedirect(true);
                }else{
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });
                    
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    alert.show(res.data.status.code);
                    profileLoad();
                }
            })
        }

        if(ResetFormProfile === 'username'){
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += userPassword;
            StringSignature += tableusername;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/V01/changeusername',
                headers : {
                    signature : signature
                },
                data : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    username : useruuid.tableusername,
                    tokenlogin : useruuid.tokenlogin,
                    userpassword : userPassword,
                    tableusername : tableusername
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    localStorage.removeItem('useruuid');
                    dispatch({
                        type : 'NOTIF',
                        newNotif : 'Please Re-login!'
                    });
                    setRedirect(true);
                }else{
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });
                    
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    alert.show(res.data.status.code);
                    profileLoad();
                }
            })
        }
    }

    const copyClipboard = () => {
        navigator.clipboard.writeText(copyClipboardValue.current.value);
        setCopyied(true);
    }

    const fullNameEdit = () => {
        dispatch({
            type : 'RESET_FORM_PROFILE',
            newResetFormProfile : 'full_name'
        });
    }

    const displayNameEdit = () => {
        dispatch({
            type : 'RESET_FORM_PROFILE',
            newResetFormProfile : 'display_name'
        });
    }

    const usernameEdit = () => {
        dispatch({
            type : 'RESET_FORM_PROFILE',
            newResetFormProfile : 'username'
        });
    }

    const saveHandle = () => {
        if(tableuserfullname !== ''){
            dispatch({
                type : 'MODAL_PASSWORD',
                newModalPassword : true
            });
        }

        if(tableuserdisplayname !== ''){
            dispatch({
                type : 'MODAL_PASSWORD',
                newModalPassword : true
            });
        }

        if(tableusername !== ''){
            dispatch({
                type : 'MODAL_PASSWORD',
                newModalPassword : true
            });
        }
    }

    setTimeout(() => {
        setCopyied(false);
    }, 5000);

    if(isRedirect){
        return <Navigate to={'/login'}/>
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <h3 className="text-center text-primary">Profile</h3>
                    <div className="profile mt-5">
                        <div className="row mb-3">
                            <label htmlFor="tableuserreferralcode" className="form-label">Referral Code</label>
                            <div className="col">
                                <input type="text" name="tableuserreferralcode" ref={copyClipboardValue} className="form-control" value={profile.tableuserreferralcode || ''} disabled/>
                            </div>
                            <div className="col-2">
                                <button className="btn btn-primary form-control" disabled={copyied ? 'disabled' : ''} onClick={copyClipboard}>{copyied ? 'Copyied' : 'Copy'}</button>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableuseremail" className="form-label">Email</label>
                            <div className="col">
                                <input type="text" name="tableuseremail" className="form-control" value={profile.tableuseremail || ''} disabled/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableuserfullname" className="form-label">Full Name</label>
                            <div className="col">
                                <input type="text" name="tableuserfullname" disabled={ResetFormProfile === 'full_name' ? '' : 'disabled'} className="form-control" onChange={(e) => setTableuserfullname(e.target.value)} value={tableuserfullname || ''}/>
                            </div>
                            <div className="col-2 text-end">
                                {ResetFormProfile === 'full_name' ? 
                                <button type="button" className="btn btn-success form-control" onClick={saveHandle}>Save</button>
                                :
                                <button type="button" className="btn btn-primary form-control" onClick={fullNameEdit}>Edit</button>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableuserdisplayname" className="form-label">Display Name</label>
                            <div className="col">
                                <input type="text" name="tableuserdisplayname" disabled={ResetFormProfile === 'display_name' ? '' : 'disabled'} className="form-control" onChange={(e) => setTableuserdisplayname(e.target.value)} value={tableuserdisplayname || ''}/>
                            </div>
                            <div className="col-2 text-end">
                                {ResetFormProfile === 'display_name' ? 
                                <button type="button" className="btn btn-success form-control" onClick={saveHandle}>Save</button>
                                :
                                <button type="button" className="btn btn-primary form-control" onClick={displayNameEdit}>Edit</button>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableusername" className="form-label">Username</label>
                            <div className="col">
                                <input type="text" name="tableusername" disabled={ResetFormProfile === 'username' ? '' : 'disabled'} className="form-control" onChange={(e) => setTableusername(e.target.value)} value={tableusername || ''}/>
                            </div>
                            <div className="col-2 text-end">
                                {ResetFormProfile === 'username' ? 
                                <button type="button" className="btn btn-success form-control" onClick={saveHandle}>Save</button>
                                :
                                <button type="button" className="btn btn-primary form-control" onClick={usernameEdit}>Edit</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="pt-4">
                        <span className="d-block text-center">Last password change 30-05-2022 18:23</span>
                        <span className="d-block text-center">Click <Link to="/forgot-password">Forgot Password</Link> to update your password</span>
                    </div>
                    <ModalPassword savehandleconfirmation={saveHandleConfirmation}
                    show={modalPassword} onHide={() => dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    })}/>
                </div>
            </div>
        </>
    );
}

export default Profile;