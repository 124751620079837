import { useCallback, useEffect, useState } from "react";
import { Link, Navigate } from 'react-router-dom';
import { useAlert } from "react-alert";

import cryptoJS from "crypto-js";
import axios from "axios";

import Navbar from "../layout/Navbar";
import { useDispatch, useSelector } from "react-redux";
import runEncrypt from "../../container/hash/Encrypt";

import Footer from "../layout/Footer";

import runService from "../library/Service";

const Login = () => {
    const [Apiip, setApiip] = useState({});
    const [Tableusername, setTableusername] = useState(null);
    const [Tablepassword, setTablepassword] = useState(null);
    const [isCodeError, setCodeError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [eye, setEye] = useState(false);

    const notif = useSelector(state => state.notif);
    const alert = useAlert();

    const dispatch = useDispatch();

    const loadApiip = useCallback(() => {
        axios({
            method : 'GET',
            url : process.env.REACT_APP_APIIP+'/json',
        }).then(res => {
            console.log(res);
            setApiip(res.data);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        loadApiip();
    }, [loadApiip]);

    const onSubmit = (e) => {
        e.preventDefault();
        setBtnLoading(true);

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += Tableusername;
        StringSignature += Tablepassword;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/V01/signin',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                tableusername : Tableusername,
                tableuserpassword : Tablepassword,
                tableuserlanguage : 'EN',
                tableusergeolocationinfoip : Apiip.ip.toString(),
                tableusergeolocationinfoversion : Apiip.version,
                tableusergeolocationinfocity: Apiip.city,
                tableusergeolocationinforegion : Apiip.region,
                tableusergeolocationinforegion_code : Apiip.region_code,
                tableusergeolocationinfocountry : Apiip.country,
                tableusergeolocationinfocountry_name : Apiip.country_name,
                tableusergeolocationinfocountry_code : Apiip.country_code,
                tableusergeolocationinfocountry_code_iso3 : Apiip.country_code_iso3,
                tableusergeolocationinfocountry_capital : Apiip.country_capital,
                tableusergeolocationinfocountry_tld : Apiip.country_tld,
                tableusergeolocationinfocontinent_code : Apiip.continent_code,
                tableusergeolocationinfoin_eu : Apiip.in_eu.toString(),
                tableusergeolocationinfopostal : "null",
                tableusergeolocationinfolatitude : Apiip.latitude.toString(),
                tableusergeolocationinfolongitude : Apiip.longitude.toString(),
                tableusergeolocationinfotimezone : Apiip.timezone,
                tableusergeolocationinfoutc_offset : Apiip.utc_offset.toString(),
                tableusergeolocationinfocountry_calling_code : Apiip.country_calling_code.toString(),
                tableusergeolocationinfocurrency : Apiip.currency,
                tableusergeolocationinfocurrency_name : Apiip.currency_name,
                tableusergeolocationinfolanguages : Apiip.languages,
                tableusergeolocationinfocountry_area : Apiip.country_area.toString(),
                tableusergeolocationinfocountry_population : Apiip.country_population.toString(),
                tableusergeolocationinfoasn : Apiip.asn.toString(),
                tableusergeolocationinfoorg : Apiip.org,
            }
        }).then(res => {
            if(res.data.status.status === 0){
                if(res.data.status.code === '888065165005'){
                    alert.error(runService.getServiceLanguage(res.data.status.code));
                }else if(res.data.status.code === '888065220005'){
                    // alert.error(res.data.status.errormessage);
                    alert.error(runService.getServiceLanguage(res.data.status.code));
                }else{
                    setCodeError(res.data.status.code.substring(0,9));
                    setErrorMessage(res.data.status.code);
                }
            }else{
                setCodeError(null);
                localStorage.setItem('useruuids', runEncrypt.runEncodeEncrypt(JSON.stringify(res.data.userinfo)));
                setIsRedirect(true);
            }
            setBtnLoading(false);
            console.log(res.data);
        }).catch(error => {
            console.log(error);
        })
    }

    const eyeHandle = () => {
        if(eye){
            setEye(false);
        }else{
            setEye(true);
        }
    }

    useEffect(() => {
        if(notif !== ''){
            alert.show(notif);

            dispatch({
                type : 'notif',
                newNotif : ''
            })
        }
    }, [notif, dispatch, alert]);

    const validationBorder = () => {
        var validationClass;

        if(isCodeError === '888999999'){
            validationClass = 'form-control border border-danger';
        }else if(isCodeError === '888065010'){
            validationClass = 'form-control border border-danger';
        }else if(isCodeError === '888065090'){
            validationClass = 'form-control border border-danger';
        }else{
            validationClass = 'form-control border-right-none';
        }

        return validationClass;
    }

    if(isRedirect){
        return <Navigate to={`/`}/>
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="container mt-100">
                    <div className="card w-569 m-auto p-3 shadow">
                        <div className="card-body">
                            <h3>Login</h3>
                            <div className="mt-4 loginForm">
                                <form method="POST" onSubmit={onSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="tableusername" className="form-label">Username</label>
                                        <input type="text" name="tableusername" className={ isCodeError === '888065015' ? 'form-control border border-danger' : 'form-control'} onChange={(e) => setTableusername(e.target.value)}/>
                                        {isCodeError === '888065015' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null}
                                    </div>
                                    <label htmlFor="tableuserpassword" className="form-label">Password</label>
                                    <div className="input-group">
                                        <input type={eye ? 'text' : 'password'} className={validationBorder()} autoComplete="on" onChange={(e) => setTablepassword(e.target.value)}/>
                                        <span className="input-group-text bg-none pointer" onClick={eyeHandle}>
                                            {eye ? 
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye.png'} alt="eye.png"></img>:
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye-slash.png'} alt="eye-slash.png"></img> }
                                        </span>
                                    </div>
                                    {isCodeError === '888999999' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null}
                                    {isCodeError === '888065090' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null}
                                    {isCodeError === '888065010' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null}
                                    <div className="row pt-2">
                                        <div className="col">
                                            <Link to={`/forgot-password`} className="text-start d-block">Forgot Password?</Link>
                                        </div>
                                        <div className="col">
                                            <Link to={`/forgot-username`} className="text-end d-block">Forgot Username?</Link>
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        {btnLoading ? 
                                            <button className="btn btn-primary btw-150 m-auto d-block" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                            </button> :
                                            <button className="btn btn-primary btw-150 m-auto d-block" type="submit">Login</button>
                                        }
                                    </div>
                                </form>
                            </div>
                            <div className="text-center">
                                <span>Don't have an account <Link to={`/new-register`} className="text-decoration-none">Register Now</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Login;