import { useEffect } from "react";

import cryptoJS from "crypto-js";
import axios from "axios";

import runDecrypt from "../../container/hash/Decrypt";

const Logout = () => {
    useEffect(() => {
        let useruuid = JSON.parse(runDecrypt.runDecodeEncrypt(localStorage.getItem('useruuids')));
        // let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;        

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/v01/logout',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                username : useruuid.tableusername,
                tokenlogin : useruuid.tokenlogin
            }
        }).then(res => {
            if(res.data.status.status === 1){
                localStorage.removeItem('useruuids');
                localStorage.removeItem('tokenTrans');

                window.location.href = '/';
            }else{
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);

    return(
        <>
            Please wait, it is being directed...
        </>
    );
}

export default Logout;