import { useEffect, useState, useRef } from "react";
import { Navigate } from 'react-router-dom';
import { useAlert } from "react-alert";
import cryptoJS from "crypto-js";
import axios from "axios";
import Navbar from "../../layout/Navbar";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../layout/Footer";

import runDecrypt from "../../../container/hash/Decrypt";

import runService from "../../library/Service";

const HomePrivate = () => {
    const [amount, setAmount] = useState("");
    const [currencyType, setCurrencyType] = useState("");
    const [rate, setRate] = useState(0);
    const [isGenerated, setIsgenerated] = useState(false);
    const [dataCurrency, setDataCurrency] = useState({});
    // eslint-disable-next-line
    const [isCodeError, setCodeError] = useState(null);
    const [isError, setIsError] = useState(false);
    // eslint-disable-next-line
    const [errorMessage, setErrorMessage] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    // eslint-disable-next-line
    const [isRedirect, setIsRedirect] = useState(false);
    const [generatedInvoice, setGeneratedInvoice] = useState({});
    const [tableCurrency, setTableCurrency] = useState([]);
    const copyClipboardValue = useRef('');
    const copyReferralCode = useRef('');
    const copyTotal = useRef('');
    const [isRefresh, setIsRefresh] = useState(false);

    const notif = useSelector(state => state.notif);
    const alert = useAlert();

    const dispatch = useDispatch();

    let useruuid = JSON.parse(runDecrypt.runDecodeEncrypt(localStorage.getItem('useruuids'))) || [];

    const calculateTotal = () =>{

        return Math.floor((amount * rate) * 1000) / 1000;
    }

    const selectedCurrency = (label,rate) =>{
        setCurrencyType(label);
        setRate(rate);
    }

    useEffect(() => {
        setCurrencyType(dataCurrency.tablecurrencycode);
        setRate(dataCurrency.tablecurrencyrate);
    }, [dataCurrency]);

    const callCurrency = async () =>{
        await axios.get(process.env.REACT_APP_APIHOST+'/api/privatesale/V01/currencycode')
        .then((response)=>{
            let res = response.data;
            if(res.status.status === 1){
                setDataCurrency(res.tablecurrency);
            }else{
                alert.error(runService.getServiceLanguage(res.status.errormessage));
            }
        }).catch((err)=>{
            console.log(err);
        });
    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }


    useEffect(() => {
        if(notif !== ''){
            alert.show(notif);

            dispatch({
                type : 'notif',
                newNotif : ''
            })
        }
        const getCurrency = async () =>{
            
            await callCurrency();
        }
        
        getCurrency();
        // eslint-disable-next-line
    }, [notif, dispatch, alert]);


    const getList = async () => {
        let useruuid = JSON.parse(runDecrypt.runDecodeEncrypt(localStorage.getItem('useruuids')));
        // let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        await axios.post(process.env.REACT_APP_APIHOST+'/api/privatesale/V01/list',{
            platform: "WEBSITE",
            gtoken: "PASSS",
            userindex: useruuid.tableuserindex,
            username: useruuid.tableusername,
            tokenlogin: useruuid.tokenlogin,
        },{headers:{signature:signature}}).then((response)=>{
            let res = response.data;
            console.log(res);
            setTableCurrency(res.tablecurrency);
        }).catch((err)=>{
            console.log(err);
        });
    };

    // function formatNumber(num) {
    //     console.log(num);
    //     // return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // }

    useEffect(() => {
        getList();
    }, []);

    const onSubmit = async () => {
        // e.preventDefault();

        if(!localStorage.getItem('useruuids')){
            alert.show('Please login to buy');

            setTimeout(() => {
                setIsRedirect(true);
            }, 2000);
        }
        else{
            setBtnLoading(true);
            setIsgenerated(true);
            let useruuid = JSON.parse(runDecrypt.runDecodeEncrypt(localStorage.getItem('useruuids')));
            // let useruuid = JSON.parse(localStorage.getItem('useruuid'));
    
            let StringSignature = '';
    
            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += amount;
            StringSignature += currencyType;
            StringSignature += rate;
    
            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);
    
            await axios.post(process.env.REACT_APP_APIHOST+'/api/privatesale/V01/generateinvoice',{
                platform: "WEBSITE",
                gtoken: "PASSS",
                userindex: useruuid.tableuserindex,
                username: useruuid.tableusername,
                tokenlogin: useruuid.tokenlogin,
                tableprivatesaleamount: amount,
                tableprivatesalecurrencycode: currencyType,
                tableprivatesalecurrencyrate: rate,
                tableuserlanguage : 'EN'
            },{headers:{signature:signature}}).then((response)=>{
                if(response.data.status.status === 1){
                    console.log(response.data);
                    setIsError(false);
                    setGeneratedInvoice(response.data.tableinvoice);
                    getList();
                    alert.success('Success Generate');
                }else{
                    setIsError(true);
                    setErrorMessage(response.data.status.code);
                    alert.error(runService.getServiceLanguage(response.data.status.code));
                    console.log(response.data);
                }
            }).catch((err)=>{
                console.log(err);
            });
    
            setBtnLoading(false);
        }
    }

    
    const copyHandle = () => {
        navigator.clipboard.writeText(copyClipboardValue.current.value);
    }

    const copyHandleReferralCode = () => {
        navigator.clipboard.writeText(copyReferralCode.current.value);
    }

    const copyTotalHandle = () => {
        navigator.clipboard.writeText(copyTotal.current.value);
    }

    const refreshHandle = () => {
        getList();
        setIsRefresh(true);
    }

    setTimeout(() => {
        setIsRefresh(false);
    }, 1000);

    if(isRedirect){
        return <Navigate to={`/login`}/>
    }

    const qrCode = () => {
        if(btnLoading === false){
            if(isGenerated){
                if(isError !== true){
                    return(
                        <>
                            <h3>Wallet Address</h3>
                            <img src={generatedInvoice ? generatedInvoice.tableinvoicewalletaddressqrcode : ""} className="d-block m-auto qrcode" alt="null"></img>
                        </>
                    );
                }
            }
        }
    }

    const walletAddress = () => {
        if(btnLoading === false){
            if(isGenerated){
                if(isError !== true){
                    return(
                        <>
                            <div class="input-group mt-4 ml-4 mr-4">
                                <input type="text" ref={copyClipboardValue} class="form-control" readOnly defaultValue={generatedInvoice.tableinvoicewalletaddress} aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                <button class="btn btn-info" type="button" id="button-addon2" onClick={copyHandle}>Copy</button>
                            </div>
                            <div className="walletInformation pt-2">
                                <li>Address can be used repeatedly for deposit. <span style={{ fontWeight: 700}}>(this is Tron / TRC20 address)</span></li>
                                <li>Deposit will be shown in your balance <span style={{ fontWeight: 700}}>after 10 confirmation(s)</span></li>
                                <li><span style={{ fontWeight: 700}}>Sending tokens other than USDT to above address will result in your tokens being lost.</span></li>
                            </div>
                            <hr/>
                        </>
                    );
                }
            }
        }
    }

    const uniqueCode = () => {
        if(btnLoading === false){
            if(isGenerated){
                if(isError !== true){
                    return(
                        <>
                            <div className="mb-3">
                                <label htmlFor="currency_rate" className="form-label" style={{ fontWeight: 700}}>Unique Code</label>
                                <input type="text" name="unique_code" className={'form-control'} value={generatedInvoice.tableinvoiceuniquecode} readOnly={true} disabled={true}/>
                            </div>
                            <label htmlFor="total" className="form-label" style={{ fontWeight: 700}}>Total</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" ref={copyTotal} value={generatedInvoice.tableinvoicetotal} disabled/>
                                <button class="btn btn-info" type="button" id="button-addon2" onClick={copyTotalHandle}>Copy</button>
                            </div>
                            <span className="walletInformation pt-2">
                                <li>Please transfer the exact above number <span style={{ fontWeight: 700}}>( Total Received USDT must be the same as Total above ).</span></li>
                                <li>The unique code is used to verify your transaction.</li>
                                <li>This invoice is valid for <span style={{ fontWeight: 700}}>one hour (60 minutes) only.</span></li> 
                            </span>
                            {/* <div className="mb-3">
                                <label htmlFor="total" className="form-label" style={{ fontWeight: 700}}>Total</label>
                                <input type="text" name="total" className={'form-control'} value={generatedInvoice.tableinvoicetotal} readOnly={true} disabled={true}/>
                                <span className="walletInformation pt-2">
                                    <li>This invoice is valid for <span style={{ fontWeight: 700}}> one hour (60 minutes) </span> only</li>
                                    <li>Please transfer with last three digit unique code.</li>
                                    <li>The unique code is used to verify your transaction.</li> 
                                </span>
                            </div> */}
                        </>
                    );
                }
            }
        }
    }

    const checktotal = (result) => {
        let total;

        if(result.tableprivatesaletotalsync === 0){
            total = result.tableprivatesaletotal;
        }else{
            total = result.tableprivatesaletotalsync;
        }


        return total;
    }
    
    let resultCurrencyList = tableCurrency?.map(function(result, i){
        return(
            <div key={i}>
                <div className="row text-center m-2 list-transaction">
                    <div className="col fs-14">{ formatDate(result.tableprivatesaletimestamp) }</div>
                    {checktotal(result)}
                    { result.tableprivatesalestatusdescription === 'UNPAID' ?
                    <div className="col"><span className="badge text-bg-warning btw-150" style={{ fontWeight: 700}}>{ result.tableprivatesalestatusdescription }</span></div> 
                    : <div className="col"><span className="badge text-bg-success btw-150" style={{ fontWeight: 700}}>{ result.tableprivatesalestatusdescription }</span></div> }
                </div>
            </div>
        )
    })

    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="bannersale">
                    <img src={process.env.PUBLIC_URL+'/images/components/banner.jpg'} alt="banner.jpg"/>
                </div>
                <div className="bannersalemobile">
                    <img src={process.env.PUBLIC_URL+'/images/components/banner-mobile.jpg'} alt="banner-mobile.jpg"/>
                </div>
                <div className="card w-569 m-auto shadow mtc-53">
                    <div className="card-body">
                        {/* <div className="mb-5">
                            <h3 style={{float: 'left'}}>Private Sale XDW</h3>
                        </div> */}
                        <div className="mt-4 loginForm">
                            <div className="mb-3">
                                <label htmlFor="amount" className="form-label" style={{ fontWeight: 700}}>Amount ( Min. Amount = 10 XDW )</label>
                                <input type="text" name="amount" className={isCodeError === '888045020' ? 'form-control border border-danger' : 'form-control'} onChange={(e) => setAmount(e.target.value)}/>
                                { isError ? 
                                <span className="text-danger">{ runService.getServiceLanguage(errorMessage) }</span>
                                : '' }
                            </div>
                            <div className="mb-3">
                                <label htmlFor="fee" className="form-label" style={{ fontWeight: 700}}>Fee</label>
                                <input type="text" name="fee" defaultValue="Totally Free in this Private Sale!" className="form-control" disabled/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="currency_option" className="form-label" style={{ fontWeight: 700}}>Select Currency</label><br/>
                                <button className="btn btn-primary d-block m-auto" onClick={()=>selectedCurrency(dataCurrency.tablecurrencycode,dataCurrency.tablecurrencyrate)}>USDT TRC20</button>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="currency_rate" className="form-label" style={{ fontWeight: 700}}>Currency Rate</label>
                                <input type="text" name="currency_rate" className={'form-control'} value={rate || 0} readOnly={true} disabled={true}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="total" className="form-label" style={{ fontWeight: 700}}>Sub Total</label>
                                <input type="text" name="total" className={'form-control'} value={calculateTotal()} readOnly={true} disabled={true}/>
                            </div>
                            <div className="mb-3 mt-4">
                                {btnLoading ? 
                                    <button className="btn btn-primary btw-150 m-auto d-block" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                    </button> :
                                    <button className={`btws-150 m-auto d-block btn btn-primary`} onClick={()=>currencyType !== "" ? onSubmit() : null}>Generate Invoice</button>
                                }
                            </div>
                        </div>
                        {localStorage.getItem('useruuids') ? <>
                        {uniqueCode()}
                        <hr/>
                        <div className="mb-3 text-center">
                            {qrCode()}
                            {/* <p className="m-auto" style={qrinfo}>Here is your QR-Code please scan with your device.</p> */}
                            {walletAddress()}
                            <div className="col-md-12 mt-4 mb-3">
                                <p style={{ fontWeight: 700}}>Transaction(s)</p>
                                {isRefresh ? 
                                <><img src={process.env.PUBLIC_URL+'/images/components/200.gif'} alt="200.gif" width="92"/><br/></> :
                                <><FontAwesomeIcon icon={faArrowsRotate} className="pointer border" onClick={refreshHandle} size="1x"/><br/></> }
                                <span className="font-size-10">Refresh transaction(s)</span>
                                <div className="row text-center m-2">
                                    <div className="col">
                                        <label style={{ fontWeight: 700}}>Date</label>
                                    </div>
                                    <div className="col">
                                        <label style={{ fontWeight: 700}}>Total</label>
                                    </div>
                                    <div className="col">
                                        <label style={{ fontWeight: 700}}>Status</label>
                                    </div>
                                </div>

                                <div className="listTransaction">
                                {resultCurrencyList}
                                </div>
                            </div>
                        </div>
                        <br/>
                        <hr/>
                        <span className="d-block text-center" style={{ fontWeight: 700}}>Refer & Get Rewarded</span>
                        <div class="input-group mt-2 mb-2 ml-4 mr-4">
                            <input type="text" ref={copyReferralCode} class="form-control" readOnly defaultValue={process.env.REACT_APP_HOSTAPP+'new-register/'+useruuid.tableuserreferralcode} aria-label="Recipient's username" aria-describedby="button-addon2"/>
                            <button class="btn btn-info" type="button" id="button-addon2" onClick={copyHandleReferralCode}>Copy</button>
                        </div>
                        <p className="text-center">A big surprise is waiting for u at the end of this private sale!</p> 
                        </>: '' }
                        {/* <div className="mb-3 mt-5">
                            <button className="btn btn-primary btw-150 m-auto d-block" onClick={()=>setIsgenerated(false)}>Back</button>
                        </div> */}
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
            <Footer/>
        </>
    );
}

export default HomePrivate;