const Translation = () => {
    return(
        <>
            <div className="card list-translation">
                <div className="card-body">
                    <div className="translation">
                        <img src="https://flagcdn.com/w40/us.png" alt="us" className="img-radius"/>
                        <img src="https://flagcdn.com/w40/id.png" alt="id" className="img-radius"/>
                        <img src="https://flagcdn.com/w40/cn.png" alt="cn" className="img-radius"/>
                        <img src="https://flagcdn.com/w40/tw.png" alt="id" className="img-radius"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Translation;