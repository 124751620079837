import CryptoJS from 'crypto-js';

const decodeEncrypt = (value) => {
    let resultDecode;

    if(value !== null){
        let encodedWord = CryptoJS.enc.Base64.parse(value); 
        let decoded = CryptoJS.enc.Utf8.stringify(encodedWord); 

        let bytes  = CryptoJS.AES.decrypt(decoded, process.env.REACT_APP_SECRET);
        let decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        resultDecode = decryptedData
    }else{
        resultDecode = null;
    }
    
    return resultDecode;
}

const runDecrypt = {
    runDecodeEncrypt(value){
        return decodeEncrypt(value);
    }
}

export default runDecrypt;