import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { positions, Provider as Providers } from "react-alert";

import AlertTemplate from "react-alert-template-basic";

// import Home from '../components/pages/home/Home';
import HomePrivate from '../components/pages/home/HomePrivate';
import Template from '../components/layout/Template';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import Success from '../components/notification/Success';
import NewRegister from '../components/auth/NewRegister';
import Logout from '../components/auth/Logout';
import NewPassword from '../components/reset/NewPassword';
import ForgotPassword from '../components/reset/ForgotPassword';
import ForgotUsername from '../components/reset/ForgotUsername';
import Profile from '../components/pages/profile/Profile';
import MainWallet from '../components/pages/mainwallet/MainWallet';
import NotFound from '../components/pages/404/NotFound';

const options = {
	timeout: 5000,
	position: positions.TOP_RIGHT
};

const Router = () => {
    return(
        <>
			<Providers template={AlertTemplate} {...options}>
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<HomePrivate/>}/>
						{/* <Route path='/home' element={<Template/>}/> */}
						<Route path='/login' element={<Login/>}/>
						<Route path='/register' element={<Register/>}/>
						<Route path='/success' element={<Success/>}/>
						<Route path='/new-password/:id' element={<NewPassword/>}/>
						<Route path='/forgot-password' element={<ForgotPassword/>}/>
						<Route path='/forgot-username' element={<ForgotUsername/>}/>
						<Route path='/logout' element={<Logout/>}/>
						
						<Route path='/new-register' element={<NewRegister/>}/>
						<Route path='/new-register/:refby' element={<NewRegister/>}/>

						<Route path='*' element={<NotFound/>}/>

						{/* <Route path='/profile' element={<Profile/>}/> */}
						{/* <Route path='/main-wallet' element={<MainWallet/>}/> */}
					</Routes>
				</BrowserRouter>
			</Providers>
        </>
    );
}

export default Router;