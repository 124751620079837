import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import cryptoJS from "crypto-js";
import axios from "axios";
import { useState } from "react";

const MainWallet = () => {
    const [TableWalletAccount, setTableWalletAccount] = useState('-');

    const generateHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/mainwallet/V01/generate',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                username : useruuid.tableusername,
                tokenlogin : useruuid.tokenlogin
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setTableWalletAccount(res.data.tablewallet.tablewalletaccount);
            }else{
                setTableWalletAccount(res.data.tablewallet.tablewalletaccount);
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <h3 className="text-center text-primary">Main Wallet</h3>
                    <div className="main-wallet mt-5">
                        <div className="row">
                            <div className="col-8">
                                <div className="card">
                                    <div className="card-body p-5">
                                        <div className="row">
                                            <h5>Main Wallet</h5>
                                            <div className="col">
                                                <p className="pt-2">{TableWalletAccount}</p>
                                            </div>
                                            <div className="col">
                                                { TableWalletAccount !== '' ?
                                                <button className="btn btn-outline-info float-right-xdw" onClick={generateHandle}>Generate</button> : '' }
                                            </div>
                                        </div>
                                        <div className="pt-5">
                                            <h5>Saldo</h5>
                                            <p>1,000,000,000 XDW</p>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col">
                                                <button className="btn btn-primary w-100">Top Up</button>
                                            </div>
                                            <div className="col">
                                                <button className="btn btn-primary w-100">Withdraw</button>
                                            </div>
                                            <div className="col">
                                                <button className="btn btn-primary w-100">Transfer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <div className="card-body p-5">
                                        <h5>Saldo</h5>
                                        <p>1,000,000,000 XDW</p>
                                        <div className="pt-4">
                                            <p className="text-danger">
                                                This amount of DWA is used only for token
                                                creating and transaction fee of tokens created
                                                under your account.
                                            </p>
                                        </div>
                                        <button className="btn btn-success w-100 mt-46">Top Up</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainWallet;