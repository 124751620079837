import CryptoJS from 'crypto-js';

const encodeEncrypt = (value) => {
    let encryptAes = CryptoJS.AES.encrypt(value, process.env.REACT_APP_SECRET).toString();
    let encodedWord = CryptoJS.enc.Utf8.parse(encryptAes);
    let encoded = CryptoJS.enc.Base64.stringify(encodedWord);
    
    return encoded;
}

const runEncrypt = {
    runEncodeEncrypt(value){
        return encodeEncrypt(value);
    }
}

export default runEncrypt;