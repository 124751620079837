import { useState } from "react";
import { Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import Navbar from "../layout/Navbar";
import axios from "axios";

import Footer from "../layout/Footer";

import runService from "../library/Service";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [isRedirect, setIsRedirect] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const [isCodeError, setIsCodeError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
        setBtnLoading(true);

        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += email;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/V01/forgotpassword',
            headers : {
                signature : signature
            },
            data : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                tableuseremail : email,
                tableuserlanguage : 'EN'
            }
        }).then(res => {
            if(res.data.status.status === 1){
                dispatch({
                    type : 'PAGE_SUCCESS',
                    newPageSuccess : 'emailSuccessForgot'
                });
                setIsRedirect(true);
            }else{
                console.log(res.data);
                setIsCodeError(res.data.status.code.substring(0, 9));
                setErrorMessage(res.data.status.code);
            }
            setBtnLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }

    if(isRedirect){
        return <Navigate to={`/success`}/>
    }
    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="container mt-100">
                    <div className="card w-569 m-auto p-3 shadow">
                        <div className="card-body">
                            <h3>Forgot Password</h3>
                            <span>Enter your username/email associated with your account and we'll send an email with instructions to reset your password</span>
                            <div className="mt-4 loginForm">
                                <form method="POST" onSubmit={onSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="tableuseremail" className="form-label">Email/Username</label>
                                        <input type="text" name="tableuseremail" className={isCodeError === '888065010' ? 'form-control border border-danger' : 'form-control'} onChange={(e) => setEmail(e.target.value)}/>
                                        {isCodeError === '888065010' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : ''}
                                    </div>
                                    <div className="mb-3">
                                        {btnLoading ? 
                                            <button className="btn btn-primary btw-150 m-auto d-block" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                            </button> :
                                        <button type="submit" className="btn btn-primary d-block m-auto">Reset Password</button> }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default ForgotPassword;