import { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';

import cryptoJS from "crypto-js";
import axios from 'axios';

import Navbar from "../layout/Navbar";

import Footer from '../layout/Footer';

import runService from '../library/Service';

const NewPassword = () => {
    const { id } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRedirect, setIsRedirect] = useState(false);

    const [isCodeError, setCodeError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);

    const [eye, setEye] = useState(false);
    const [eyes, setEyes] = useState(false);

    const dispatch = useDispatch();
    const alert = useAlert();

    const onSubmit = (e) => {
        e.preventDefault();
        
        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += id;
        StringSignature += password

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        if(password !== confirmPassword){
            setCodeError('888005085');
            setErrorMessage('Confirm password is not the same as password.');
        }else{
            setBtnLoading(true);
            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/V01/newpassword',
                headers : {
                    signature : signature
                },
                data : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    tableuseremailverificationcode : id,
                    tableuserpasswordnew : password,
                    tableuserlanguage : 'EN'
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    dispatch({
                        type : 'PAGE_SUCCESS',
                        newPageSuccess : 'passwordSuccess'
                    });
                    setIsRedirect(true);
                }else{
                    setCodeError(res.data.status.code.substring(0, 9));
                    setErrorMessage(res.data.status.code);
                    alert.error(runService.getServiceLanguage(res.data.status.code));
                }
                setBtnLoading(false);
                console.log(res.data);
            }).catch(error => {
                console.log(error);
            })
        }
    }

    const eyeHandle = () => {
        if(eye){
            setEye(false);
        }else{
            setEye(true);
        }
    }

    const eyesHandle = () => {
        if(eyes){
            setEyes(false);
        }else{
            setEyes(true);
        }
    }

    if(isRedirect){
        return <Navigate to={`/success`}/>
    }
    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="container mt-100">
                    <div className="card w-569 m-auto p-3 shadow">
                        <div className="card-body">
                            <h3>New Password</h3>
                            <div className="mt-4 loginForm">
                                <form method="POST" onSubmit={onSubmit}>
                                    <label htmlFor="tableuserpasswordnew" className="form-label">Password</label>
                                    <div className="input-group mb-2">
                                        <input type={eye ? 'text' : 'password'} className={isCodeError === '888065090' ? 'form-control border border-danger' : 'form-control border-right-none'} autoComplete="on" onChange={(e) => setPassword(e.target.value)}/>
                                        <span className="input-group-text bg-none pointer" onClick={eyeHandle}>
                                            {eye ? 
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye.png'} alt="eye.png"></img>:
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye-slash.png'} alt="eye-slash.png"></img> }
                                        </span>
                                    </div>
                                    {isCodeError === '888065090' ? <span className="text-danger d-block">{runService.getServiceLanguage(errorMessage)}</span> : null}
                                    <span className="description">Password must be minimum 8 characters, at least 1 capital letter, 2 digit numbers, one special character (!@#$%^&*) and not contain space</span>
                                    <label htmlFor="tableuserpasswordconfirmation" className="form-label mt-3">Confirm Password</label>
                                    <div className="input-group mb-2">
                                        <input type={eyes ? 'text' : 'password'} className={isCodeError === '888005085' ? 'form-control border border-danger' : 'form-control border-right-none'} autoComplete="on" onChange={(e) => setConfirmPassword(e.target.value)}/>
                                        <span className="input-group-text bg-none pointer" onClick={eyesHandle}>
                                            {eyes ? 
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye.png'} alt="eye.png"></img>:
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye-slash.png'} alt="eye-slash.png"></img> }
                                        </span>
                                    </div>
                                    {isCodeError === '888005085' ? <span className="text-danger d-block text-end">{runService.getServiceLanguage(errorMessage)}</span> : null}
                                    <div className="mt-3">
                                        {btnLoading ? 
                                        <button className="btn btn-primary btw-150 m-auto d-block" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                        </button>:
                                        <button type="submit" className="btn btn-primary d-block m-auto">Create Password</button>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default NewPassword;